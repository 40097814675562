<template>
  <v-card>
    <v-card-title class="primary white--text">
      Detalhes do E-mail
      <v-spacer></v-spacer>
      <v-btn
        class="primary elevation-0"
        :to="{ name: 'solicitation-tag', params: { email_id: emailContent.id, emailRequester: emailContent.from } }"
      >
        <v-icon class="white--text" title="Criar Solicitações">mdi-folder-pound</v-icon>
      </v-btn>
      <v-btn
        class="primary elevation-0"
        v-if="!showLinkComponent"
        @click="displaySolicitationSyncListComponent(emailContent.id)"
      >
        <v-icon
          title="Vincular e-mail na solicitação"
          class="white--text"
        >{{ type === 'sync' ? syncIcon : syncedIcon }}</v-icon>
      </v-btn>
      <v-btn class="primary elevation-0" @click="closeBox">
        <v-icon class="white--text">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <SolicitationSyncList
      v-if="showLinkComponent"
      :type="type"
      :selectedEmailId="emailContent.id"
    />
    <v-card-text>
      <v-row>
        <v-col>
          <span class="mr-4">De</span>
          <v-chip pill> {{ emailContent.from }} </v-chip>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col>
          <span>{{ emailContent.subject }}</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col v-if="emailContent.type_body === 'text'">
          <v-textarea
            label="Conteúdo do e-mail"
            solo
            flat
            hide-details
            auto-grow
            v-model="emailContent.body"
            readonly
          ></v-textarea>
        </v-col>
        <v-col v-else>
          <ShadowHtml :key="emailContent.id" :content="emailContent.body" ></ShadowHtml>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span class="align description-text">Anexos:</span>
          <v-chip small class="ml-2" v-for="attachment in emailContent.attachments" :key="attachment.id">
            <a @click="downloadFile(attachment)">
              {{ attachment.name }}
            </a>
          </v-chip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import SolicitationSyncList from "./SolicitationSyncList.vue";
import ShadowHtml from "@/components/interaction/shadowHtmlComponent.vue";
import { mapActions } from "vuex";
export default {
  props: ["emailContent", "showIcon", "type"],
  components: {
    ShadowHtml,
    SolicitationSyncList
  },
  data() {
    return {
      syncIcon: "mdi-link-variant-plus",
      syncedIcon: "mdi-link-variant-remove",
      menu: false,
      showLinkComponent: false,
      emailData: {}
    };
  },

  methods: {
    ...mapActions("interaction", ["downloadFiles"]),

    displaySolicitationSyncListComponent() {
      this.showLinkComponent = true;
    },

    closeBox() {
      this.$emit("closeBox", false);
      this.showLinkComponent = false;
    },

    downloadFile(file) {
      this.downloadFiles(file);
    },
  }
};
</script>
